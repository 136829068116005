import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import { Stack } from '@chakra-ui/react';
import { motion, PanInfo, useAnimation, useMotionValue } from 'framer-motion';
import { MotionFlexBox } from './motionBox/motion-box';
import {
  CarouselValueContext,
  CarouselDispatchContext,
  CarouselValueContextType,
  CarouselDispatchContextType,
} from './carousel-provider';

const MotionFlex = motion.create(MotionFlexBox);

interface CarouselTrackProps {
  children: React.ReactNode;
}

const trackTransitionProps = {
  stiffness: 400,
  type: 'spring',
  damping: 60,
  mass: 3,
};

const TrackKeyCodes = {
  next: ['ArrowRight', 'ArrowUp'],
  prev: ['ArrowLeft', 'ArrowDown'],
};

function assertIsNode(e: EventTarget | null): asserts e is Node {
  if (!e || !('nodeType' in e)) {
    throw new Error(`Not a Node`);
  }
}

export const CarouselTrack: React.FC<CarouselTrackProps> = ({ children }) => {
  const ctxValue = useContext(CarouselValueContext);
  const ctxDispatch = useContext(CarouselDispatchContext);
  // ctx values
  const {
    trackIsActive,
    activeItem,
    constraint,
    multiplier,
    itemWidth,
    positions,
  } = ctxValue as CarouselValueContextType;
  // ctx dispatch
  const { setTrackIsActive, setActiveItem } =
    ctxDispatch as CarouselDispatchContextType;

  const [dragStartPosition, setDragStartPosition] = useState(0);
  const controls = useAnimation();
  const x = useMotionValue(0);
  const node = useRef<HTMLDivElement | null>(null);

  const handleDragStart = () => setDragStartPosition(positions[activeItem]);

  const handleDragEnd = (
    _: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo
  ): void => {
    const distance = info.offset.x;
    const velocity = info.velocity.x * multiplier;
    const countDirection = velocity < 0 || distance < 0 ? Math.min : Math.max;

    const extrapolatedPosition =
      dragStartPosition + countDirection(velocity, distance);

    const closestPosition = positions.reduce((prev: number, curr: number) => {
      return Math.abs(curr - extrapolatedPosition) <
        Math.abs(prev - extrapolatedPosition)
        ? curr
        : prev;
    }, 0);
    if (!(closestPosition < positions[positions.length - constraint])) {
      setActiveItem(positions.indexOf(closestPosition));
      controls.start({
        x: closestPosition,
        transition: { velocity: info.velocity.x, ...trackTransitionProps },
      });
    } else {
      setActiveItem(positions.length - constraint);
      controls.start({
        x: positions[positions.length - constraint],
        transition: { velocity: info.velocity.x, ...trackTransitionProps },
      });
    }
  };

  const handleAnimation = useCallback(() => {
    controls.start({
      x: positions[activeItem],
      transition: {
        ...trackTransitionProps,
      },
    });
  }, [activeItem, controls, positions]);

  const handleClick = useCallback(
    (event: MouseEvent) => {
      assertIsNode(event.target);
      node?.current?.contains(event.target)
        ? setTrackIsActive(true)
        : setTrackIsActive(false);
    },
    [setTrackIsActive]
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (trackIsActive) {
        if (activeItem < positions.length - constraint) {
          if (TrackKeyCodes.next.includes(event.key)) {
            event.preventDefault();
            setActiveItem((prev: number) => prev + 1);
          }
        }
        if (activeItem > positions.length - positions.length) {
          if (TrackKeyCodes.prev.includes(event.key)) {
            event.preventDefault();
            setActiveItem((prev: number) => prev - 1);
          }
        }
      }
    },
    [trackIsActive, setActiveItem, activeItem, constraint, positions.length]
  );

  useEffect(() => {
    handleAnimation();
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('mousedown', handleClick);
    };
  }, [handleClick, handleAnimation, handleKeyDown]);

  return (
    <>
      {!!itemWidth && (
        <Stack
          height="100%"
          width="100%"
          overflowX="hidden"
          ref={node}
          spacing={5}
          className="carousel-track-stack"
        >
          <MotionFlex
            dragConstraints={node}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            animate={controls}
            style={{ x }}
            drag="x"
            minWidth="min-content"
            height="auto"
            width="auto"
            flexWrap="nowrap"
            cursor="grab"
            _active={{ cursor: 'grabbing' }}
          >
            {children}
          </MotionFlex>
        </Stack>
      )}
    </>
  );
};
