import {
  MenuDivider as CMenuDivider,
  MenuDividerProps as CMenuDividerProps,
} from '@chakra-ui/react';
import cx from 'classnames';
import { useTheme } from '../../../theme';

export interface MenuDividerProps extends CMenuDividerProps {
  className?: string;
}

export const MenuDivider = ({ className, ...props }: MenuDividerProps) => {
  const { forgeTokens } = useTheme();
  return (
    <CMenuDivider
      className={cx('forge-menudivider', className)}
      height="0"
      width="100%"
      marginTop={0}
      marginBottom={0}
      borderBottom={`1px solid ${forgeTokens.colors.lineBorder}`}
      {...props}
    />
  );
};
