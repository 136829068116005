import React from 'react';
import {
  MenuButton as CMenuButton,
  MenuButtonProps as CMenuButtonProps,
} from '@chakra-ui/react';
import cx from 'classnames';
import { Button, Variant as ButtonVariant } from '../button';
import { VisuallyHidden } from '../visually-hidden';
import { CaretDown, DotsThree } from '@mezzoforte/forge-icons';
import { useTheme } from '../../../theme';

type ButtonSize = 'default' | 'hero' | 'lists';
const sizeVariants: Record<ButtonSize, ButtonVariant | undefined> = {
  default: 'default',
  hero: 'default-hero',
  lists: 'default-lists',
};
export interface MenuButtonProps extends Omit<CMenuButtonProps, 'children'> {
  /**
   * MenuButton variant
   */
  variant?: 'default' | 'icon-only';
  /**
   * If `true`, the button will be disabled.
   */
  isDisabled?: boolean;
  /**
   * If `true`, the button will take up the full width of its container.
   */
  isFullWidth?: boolean;
  /**
   * MenuButton size variant
   * 'default' | 'hero' | 'lists'
   */
  buttonSize?: ButtonSize;
  className?: string;
  children?: React.ReactNode;
}

export const MenuButton = React.forwardRef<HTMLButtonElement, MenuButtonProps>(
  (
    {
      variant = 'default',
      buttonSize = 'default',
      children,
      className,
      ...props
    },
    ref
  ) => {
    const noKids = React.Children.count(children) === 0;
    const { forgeTokens } = useTheme();

    const isIconOnly = variant === 'icon-only';
    const isMenuButton = isIconOnly && noKids;

    return (
      <CMenuButton
        aria-label="Avaa valikko"
        as={Button}
        ref={ref}
        {...props}
        variant={sizeVariants[buttonSize]}
        {...(isIconOnly && {
          border: 0,
          minHeight: forgeTokens.styles?.button?.mintouch?.minHeight,
        })}
        {...(!isMenuButton
          ? {
              rightIcon: <CaretDown size={16} />,
            }
          : { icon: <DotsThree /> })}
        className={cx('forge-menubutton', className)}
        css={
          !isMenuButton && {
            '& span.chakra-button__icon': {
              paddingTop: '2px',
            },
          }
        }
      >
        {children}
        <VisuallyHidden>Avaa valikko</VisuallyHidden>
      </CMenuButton>
    );
  }
);
