import React, { useState, useEffect, useRef } from 'react';
import {
  useTable,
  useSortBy,
  useRowSelect,
  useFlexLayout,
  TableInstance,
} from 'react-table';
import cx from 'classnames';
import { Tr, TrProps, Th, ThProps, Td, TdProps } from '.';
import { BoxProps, Box } from '../box';
import { Flex } from '../flex';
import { Checkbox } from '../form/checkbox';
import { Heading } from '../heading';
import { SearchInput } from '../form/input';

export interface TableProps<T extends string>
  extends Omit<BoxProps, 'variant'> {
  /**
   * Set visually different styles
   */
  size?: TableSize;
  /**
   * Use sortType: 'basic' for numbers or 'datetime' for date
   */
  columns: {
    Header: Exclude<React.ReactNode, null>;
    accessor: T;
  }[];
  data: {
    [key in T]: React.ReactNode;
  }[];
  tr?: JSX.Element;
  th?: JSX.Element;
  td?: JSX.Element;
  /**
   * Text used with H2 or component
   */
  heading?: React.ReactNode;
  /**
   * Define styles for cells (and heading cells)
   */
  customCellStyles?: { [key: number]: TdStyles };
  onSearch?: (searchTerm?: string) => void;
  onSearchSubmit?: (event: React.FormEvent<HTMLInputElement>) => void;
  searchPlaceholder?: string;
  /**
   * Selectable rows <br/><b>{ selectedRowIds, selectedFlatRowsOriginal }</b>
   */
  onSelections?: (props: {
    selectedRowIds: Record<string, boolean | undefined>;
    selectedFlatRowsOriginal: { [key in T]: React.ReactNode }[];
  }) => void;
}
export const Table = <T extends string>(props: TableProps<T>) =>
  props.onSelections ? (
    <TableSelectable {...props} />
  ) : (
    <TableNotSelectable {...props} />
  );

export type TableSize = 'normal' | 'compact';
export type TdStyles = Omit<BoxProps, 'children' | 'variant'>;
export const tdth = (size: TableSize): TdStyles => ({
  padding: '0px',
  paddingTop: size === 'compact' ? 1 : 3,
  paddingBottom: size === 'compact' ? 1 : 3,
  verticalAlign: 'top',
  wordBreak: 'break-word',
});
export const defaultCellStyles = (
  size: TableSize
): Record<'base', TdStyles> => ({
  base: { paddingRight: size === 'compact' ? 2 : 3 },
});
const defaultColumnStyles = {
  // When using the useFlexLayout:
  minWidth: 30, // minWidth is only used as a limit for resizing
  width: 100, // width is used for both the flex-basis and flex-grow
  maxWidth: 200, // maxWidth is only used as a limit for resizing
};
const checkboxColumnStyles = {
  minWidth: 45,
  width: 45,
  maxWidth: 45,
};

export const useIsMount = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

const getPropComponent =
  <T extends { children?: React.ReactNode }>(
    OriginalComponent: React.ReactNode
  ) =>
  (PropComponent: React.ReactNode) =>
  ({ children, ...props }: T) => {
    if (React.isValidElement(PropComponent))
      return React.cloneElement(PropComponent, props, children);
    if (React.isValidElement(OriginalComponent))
      return React.cloneElement(OriginalComponent, props, children);
    return null;
  };

const IndeterminateCheckbox = React.forwardRef<
  HTMLInputElement,
  {
    indeterminate: boolean;
    onChange: () => void;
    checked?: boolean;
  }
>(({ indeterminate, checked, ...rest }, ref) => {
  const defaultRef = useRef<HTMLInputElement>();
  const resolvedRef = (ref || defaultRef) as React.RefObject<HTMLInputElement>;

  React.useEffect(() => {
    if (resolvedRef?.current?.indeterminate)
      resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <Checkbox ref={resolvedRef} {...rest} isChecked={!!checked} />
    </>
  );
});

const TableSelectable = <T extends string>(props: TableProps<T>) => {
  const defaultColumn = React.useMemo(() => defaultColumnStyles, []);
  const { columns, data } = props;
  const {
    state,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // @ts-expect-error external library definitions
    selectedFlatRows,
  } = useTable(
    // @ts-expect-error external library definitions
    { columns, data, defaultColumn },
    useSortBy,
    useFlexLayout,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((visibleColumn) => [
        // Let's make a column for selection
        {
          id: 'selection',
          ...checkboxColumnStyles,
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          // @ts-expect-error external library definitions
          Header: ({ getToggleAllRowsSelectedProps }) => {
            const headerRowSelectedProps = getToggleAllRowsSelectedProps();
            return <IndeterminateCheckbox {...headerRowSelectedProps} />;
          },
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          // @ts-expect-error external library definitions
          Cell: ({ row }) => {
            const rowSelectedProps = row.getToggleRowSelectedProps();
            return <IndeterminateCheckbox {...rowSelectedProps} />;
          },
        },
        ...visibleColumn,
      ]);
    }
  );
  const { selectedRowIds } = state as {
    selectedRowIds: Record<string, boolean | undefined>;
  };
  // @ts-expect-error external library definitions
  const selectedFlatRowsOriginal = selectedFlatRows.map((row) => row.original);
  const isMount = useIsMount();
  React.useEffect(() => {
    if (!isMount && props.onSelections) {
      props.onSelections({ selectedRowIds, selectedFlatRowsOriginal });
    }
  }, [isMount, props.onSelections, selectedRowIds, selectedFlatRowsOriginal]);
  const useTableProps = {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  };

  return <BaseTable {...props} {...useTableProps} />;
};

const TableNotSelectable = <T extends string>(props: TableProps<T>) => {
  const defaultColumn = React.useMemo(() => defaultColumnStyles, []);
  const { columns, data } = props;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // @ts-expect-error external library definitions
  } = useTable({ columns, data, defaultColumn }, useSortBy, useFlexLayout);
  const useTableProps = {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  };

  return <BaseTable {...props} {...useTableProps} />;
};

interface BaseTableProps<T extends string>
  extends TableProps<T>,
    Pick<
      TableInstance<Record<T, React.ReactNode>>,
      | 'getTableProps'
      | 'getTableBodyProps'
      | 'headerGroups'
      | 'rows'
      | 'prepareRow'
    > {}
const BaseTable = <T extends string>({
  size = 'normal',
  tr: propTr,
  th: propTh,
  td: propTd,
  heading,
  customCellStyles,
  onSearch,
  onSearchSubmit,
  searchPlaceholder,
  onSelections,
  width,
  maxWidth,
  className,
  getTableProps,
  getTableBodyProps,
  headerGroups,
  rows,
  prepareRow,
  ...props
}: BaseTableProps<T>) => {
  const { className: tableBodyClassName, ...tableBodyProps } =
    getTableBodyProps();
  const [searchTerm, setSearchTerm] = useState<string>('');

  const UseTr = getPropComponent<TrProps>(<Tr />)(propTr);
  const UseTh = getPropComponent<ThProps>(<Th />)(propTh);
  const UseTd = getPropComponent<TdProps>(<Td />)(propTd);

  const doOnSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setSearchTerm(event.target.value);
    if (onSearch) {
      onSearch(event.target.value);
    }
  };
  const resetSearchTerm = () => {
    setSearchTerm('');
    if (onSearch) {
      onSearch(undefined);
    }
  };
  return (
    <Box width={width} maxWidth={maxWidth}>
      <Flex justifyContent="space-between">
        {heading && typeof heading === 'string' ? (
          <Heading variant="h2">{heading}</Heading>
        ) : (
          heading
        )}
        {!!onSearch && (
          <Box flexGrow={0}>
            <SearchInput
              onChange={doOnSearch}
              onSubmit={onSearchSubmit}
              value={searchTerm}
              onClickReset={resetSearchTerm}
              placeholder={searchPlaceholder}
              maxWidth="26ch"
            />
          </Box>
        )}
      </Flex>
      <Box
        as="table"
        style={{ borderSpacing: 0, tableLayout: 'fixed' }}
        {...getTableProps()}
        {...props}
        width="100%"
        className={cx('forge-table', 'forge-table--reset', className)}
      >
        <Box
          as="thead"
          className="forge-thead forge-thead--reset"
          top="0"
          left="0"
          zIndex={1}
        >
          {headerGroups.map((headerGroup) => {
            const {
              className: headerGroupClassName,
              key,
              ...headerGroupProps
            } = headerGroup.getHeaderGroupProps();
            return (
              <Tr
                key={key}
                {...headerGroupProps}
                className={headerGroupClassName}
              >
                {headerGroup.headers.map((column, index) => {
                  const { key: thKey, ...thProps } = column.getHeaderProps(
                    // @ts-expect-error external library definitions
                    column.getSortByToggleProps()
                  );
                  return (
                    <UseTh
                      key={thKey}
                      index={index}
                      {...(customCellStyles ? { customCellStyles } : {})}
                      {...thProps}
                      maxWidth={
                        !!onSelections && index === 0
                          ? checkboxColumnStyles.maxWidth
                          : // @ts-expect-error external library definitions
                            thProps.maxWidth
                      }
                      size={size}
                    >
                      <>
                        {column.render('Header')}
                        <SortedIcon
                          // @ts-expect-error external library definitions
                          show={column.isSorted}
                          // @ts-expect-error external library definitions
                          desc={column.isSortedDesc}
                        />
                      </>
                    </UseTh>
                  );
                })}
              </Tr>
            );
          })}
        </Box>
        <Box
          as="tbody"
          {...tableBodyProps}
          className={cx(
            'forge-tbody',
            'forge-tbody--reset',
            tableBodyClassName
          )}
        >
          {rows.map((row) => {
            prepareRow(row);
            return (
              <UseTr
                {...row.getRowProps()}
                key={row.id}
                // @ts-expect-error external library definitions
                isSelected={row.isSelected}
                // @ts-expect-error external library definitions
                size={size}
              >
                {row.cells.map((cell, index) => {
                  const { key, ...tdProps } = cell.getCellProps();
                  return (
                    <UseTd
                      key={key}
                      index={index}
                      {...(customCellStyles ? { customCellStyles } : {})}
                      {...tdProps}
                      maxWidth={
                        !!onSelections && index === 0
                          ? checkboxColumnStyles.maxWidth
                          : // @ts-expect-error external library definitions
                            tdProps.maxWidth
                      }
                      size={size}
                    >
                      <>{cell.render('Cell')}</>
                    </UseTd>
                  );
                })}
              </UseTr>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

const SortedIcon = ({ show, desc }: { show?: boolean; desc?: boolean }) => {
  if (!show) return <span />;
  return <span>{desc ? ' ∨' : ' ∧'}</span>;
};

/**
 * For testing
 */

const Example = () => {
  const data = React.useMemo(
    () => [
      {
        col1: 'Data 1',
        col2: 'Data 2 ',
        col3: 'Data 3',
      },
      {
        col1: 'Data 4',
        col2: 'Data 5 ',
        col3: 'Data 6',
      },
    ],
    []
  );
  const columns = React.useMemo(
    () => [
      {
        Header: 'Example',
        accessor: 'col1',
      },
      {
        Header: 'Col 2',
        accessor: 'col2',
      },
      {
        Header: 'Col 3',
        accessor: 'col3',
      },
    ],
    []
  );
  return <Table data={data} columns={columns} width="100%" maxWidth="300px" />;
};
export const toTesting = <Example />;
