import React from 'react';
import {
  MenuList as CMenuList,
  MenuListProps as CMenuListProps,
} from '@chakra-ui/react';
import cx from 'classnames';
import { useTheme } from '../../../theme';

export interface MenuListProps extends Omit<CMenuListProps, 'position'> {
  className?: string;
}

export const MenuList = React.forwardRef<HTMLDivElement, MenuListProps>(
  ({ children, className, ...props }, ref) => {
    const { forgeTokens } = useTheme();
    return (
      <CMenuList
        ref={ref}
        {...props}
        paddingTop={0}
        paddingBottom={0}
        marginTop={2}
        borderRadius={forgeTokens.radii.basic}
        border={forgeTokens.borders.input}
        backgroundColor={forgeTokens.colors.background}
        boxShadow={forgeTokens.shadows.openElementsShadow}
        className={cx('forge-menulist', className)}
        css={{
          '& > [role=menuitem]:focus': {
            outline: 'none',
            backgroundColor: forgeTokens.colors.backgroundGray,
          },
        }}
      >
        {children}
      </CMenuList>
    );
  }
);
