import React from 'react';
import {
  Menu as CMenu,
  MenuGroup as CMenuGroup,
  type MenuProps,
  type MenuGroupProps,
} from '@chakra-ui/react';
import { MenuList } from './menu-list';
import { MenuButton } from './menu-button';
import { MenuItem } from './menu-item';
import { MenuLink } from './menu-link';
import { MenuDivider } from './menu-divider';

export { type MenuProps, MenuGroupProps };
export const Menu = ({ ...props }: MenuProps) => (
  <CMenu gutter={2} {...props} />
);

export const MenuGroup: React.FC<MenuGroupProps> = CMenuGroup;

/**
 * For testing
 */

export const toTesting = (
  <>
    <Menu>
      <MenuButton>Actions</MenuButton>
      <MenuList>
        <MenuItem onClick={() => alert('Download')}>Download</MenuItem>
        <MenuItem onClick={() => alert('Copy')}>Create a Copy</MenuItem>
        <MenuDivider />
        <MenuItem onClick={() => alert('Mark as Draft')}>
          Mark as Draft
        </MenuItem>
        <MenuItem onClick={() => alert('Delete')}>Delete</MenuItem>
        <MenuLink as="a" href="https://google.com/">
          Google.com
        </MenuLink>
      </MenuList>
    </Menu>

    <Menu>
      <MenuButton variant="icon-only">Actions</MenuButton>
      <MenuList>
        <MenuItem onClick={() => alert('Download')}>Download</MenuItem>
        <MenuItem onClick={() => alert('Copy')}>Create a Copy</MenuItem>
        <MenuDivider />
        <MenuItem onClick={() => alert('Mark as Draft')}>
          Mark as Draft
        </MenuItem>
        <MenuItem onClick={() => alert('Delete')}>Delete</MenuItem>
        <MenuLink as="a" href="https://google.com/">
          Google.com
        </MenuLink>
      </MenuList>
    </Menu>

    <Menu>
      <MenuButton variant="icon-only" />
      <MenuList>
        <MenuItem onClick={() => alert('Download')}>Download</MenuItem>
        <MenuItem onClick={() => alert('Copy')}>Create a Copy</MenuItem>
        <MenuDivider />
        <MenuItem onClick={() => alert('Mark as Draft')}>
          Mark as Draft
        </MenuItem>
        <MenuItem onClick={() => alert('Delete')}>Delete</MenuItem>
        <MenuLink as="a" href="https://google.com/">
          Google.com
        </MenuLink>
      </MenuList>
    </Menu>
  </>
);
