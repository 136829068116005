import React from 'react';
import {
  MenuItem as CMenuItem,
  MenuItemProps as CMenuItemProps,
} from '@chakra-ui/react';
import cx from 'classnames';
import { ArrowSquareOut } from '@mezzoforte/forge-icons';
import { useTheme } from '../../../theme';
import { itemStyles } from './menu-item';

interface BaseMenuLinkProps extends CMenuItemProps {
  className?: string;
  href?: string;
}
interface AsMenuLinkProps extends BaseMenuLinkProps {
  as?: React.ElementType;
}

export type MenuLinkProps = BaseMenuLinkProps | AsMenuLinkProps;

export const MenuLink = React.forwardRef<HTMLDivElement, MenuLinkProps>(
  (props, ref) => {
    const { forgeTokens } = useTheme();
    const { children, className, ...useProps } = props;
    const { as = 'a' } = props as AsMenuLinkProps;
    return (
      <CMenuItem
        ref={ref}
        {...useProps}
        alignItems="center"
        as={as}
        {...itemStyles(forgeTokens)}
        className={cx('forge-menulink', className)}
      >
        {children}
        <ArrowSquareOut
          size={14}
          style={{ marginLeft: forgeTokens.space[1] }}
        />
      </CMenuItem>
    );
  }
);
