import React from 'react';
import {
  MenuItem as CMenuItem,
  MenuItemProps as CMenuItemProps,
} from '@chakra-ui/react';
import cx from 'classnames';
import { useTheme, ITokens } from '../../../theme';

export interface MenuItemProps extends CMenuItemProps {
  /**
   * For backward compability to @reach/menu-button
   * Chakra.MenuItem supports 'isDisabled'.
   * Other Chakra.MenuItem props
   * @see https://v2.chakra-ui.com/docs/components/menu/props#menuitem-props
   */
  disabled?: boolean;
  className?: string;
}

export const MenuItem = React.forwardRef<HTMLDivElement, MenuItemProps>(
  (
    { disabled, isDisabled: itemDisabled, children, className, ...props },
    ref
  ) => {
    const { forgeTokens } = useTheme();
    const isDisabled = disabled || itemDisabled;

    return (
      <CMenuItem
        ref={ref}
        {...props}
        isDisabled={isDisabled}
        {...itemStyles(forgeTokens, isDisabled)}
        className={cx('forge-menuitem', className)}
      >
        {children}
      </CMenuItem>
    );
  }
);

export const itemStyles = (
  forgeTokens: ITokens,
  isDisabled?: boolean
): CMenuItemProps => {
  return {
    fontSize: forgeTokens.fontSizes.button,
    fontWeight: forgeTokens.fontWeights.button,
    lineHeight: forgeTokens.lineHeights.button,
    letterSpacing: forgeTokens.letterSpacings.button,
    paddingY: 2,
    paddingX: 4,
    wordBreak: 'normal',
    color: forgeTokens.colors.text,
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    ...(isDisabled && { opacity: forgeTokens.opacities.disabled }),
  };
};
