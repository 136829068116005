import { Global, css } from '@emotion/react';
import { hidePrintStyles } from '../components/primitives/hide-print';

const root = css`
  img,
  svg {
    display: inline-block;
  }

  p {
    margin-block-start: 1em;
    margin-block-end: 1em;
  }
`;
export const ForgeReset = () => (
  <>
    <Global styles={root} />
    <Global styles={hidePrintStyles} />
  </>
);
